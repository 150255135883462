/* globals $, dayjs, bootbox, bootstrap */

(function () {

    const paginationDefaults = function () {
        return {
            url: window.location.search,
            onPaginate: function (page) {
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set("page", page);
                window.location.href = "?" + queryParams.toString();
            }
        }
    };

    $.fn.pagination = function (options) {
        $(this).each(function () {
            const pagination = $(this);
            if ($(this).hasClass('evented')) {
                return;
            }
            let settings = $.extend({}, paginationDefaults(), options),
                currentIndex = parseInt(pagination.data('currentindex')),
                totalPages = parseInt(pagination.data('totalpages'));

            pagination.on('click', 'li[data-page]:not(.disabled)', function () {
                let page = $(this).data('page');
                switch (page) {
                    case 'prev':
                        page = currentIndex - 1;
                        break;
                    case 'next':
                        page = currentIndex + 1;
                        break;
                    case 'first':
                        page = 0;
                        break;
                    case 'last':
                        page = totalPages - 1;
                        break;
                    default:
                        page = page - 1;
                        break;
                }
                settings.onPaginate(page, settings.url);
                return false;
            });
            pagination.addClass('evented');
        });
    };

    $(function () {
        let errorModal = $('div#ErrorModal'),
            errorMessage = errorModal.find('#ErrorModalMessage');
        $('.health[data-error]').each(function () {
            let btn = $(this),
                msg = btn.data('error');
            if (msg !== "") {
                btn.attr('title', 'Click for more info');
                btn.on('click', function () {
                    errorMessage.text(msg);
                    errorModal.modal('show');
                });
            }
        });
    });

    $(function () {
        $('div.pagination:not(.evented)').pagination();
        $('table').on('click', '.btn-remove-note', function () {
            let tr = $(this).closest('tr'),
                href = $(this).data('href');
            bootbox.confirm({
                message: "Are you sure?",
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-outline-snapplify'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-outline-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        $s.post({
                            url: href,
                            complete: function () {
                                tr.remove();
                            }
                        });
                    }
                }
            });
            return false;
        });

        let badge = $('.user-badge'),
            showingInfo = false,
            info = badge.find('.user-badge-info'),
            img = badge.find('.user-badge-image');
        badge.on('click', function () {
            return false;
        });
        img.on('click', function () {
            if (!showingInfo) {
                info.focus();
                info.show();
                showingInfo = true;
            } else {
                info.blur();
            }
            return false;
        });
        $(document).on('click blur', function () {
            info.hide();
            showingInfo = false;
        });
    });


})();
