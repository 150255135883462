/* globals $, dayjs, bootbox, bootstrap */

(function () {

    $("input.form-control-file").on("change", function (event) {
        let input = $(this),
            previewImage = $(this).parent().find($('.previewImage'));
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
               let imgData = e.target.result,
                   imgName = event.target.files[0].name;
               input.attr("data-title", imgName);
               previewImage.attr('src', imgData);
               previewImage.show();
            }
            reader.readAsDataURL(event.target.files[0]);
        }
   });

})();
