/* globals $, dayjs, bootbox, bootstrap */

$(function () {

    const $toggle = $('.edit-literal-toggle');
    const $modal = $(`
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <form id="literalForm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Update Literal</h3>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">x</span></button>
                        </div>
                        <div class="modal-body">
                          <div class="filter filter-simple">
                                <label class="mb-2">Literal Text</label>
                                <textarea id="literalText" class="input-xlarge form-control" type="text" placeholder="Enter a literal ..." autocomplete="off"></textarea>
                                <span id="literalApp" class="hint mt-2">The literals app</span>
                                <span id="literalCode" class="hint mt-2">The literals code</span>
                                <span id="literalLocale" class="hint mt-2">The literals locale</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary save">Save</button>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `);
    const $text = $modal.find('#literalText'),
        $app = $modal.find('#literalApp'),
        $code = $modal.find('#literalCode'),
        $locale = $modal.find('#literalLocale'),
        $form = $modal.find('#literalForm'),
        $body = $('body');

    let $target = null;
    let isEditingLiterals = false;

    $toggle.on('click', function(){
        $body.toggleClass('edit-literals');
        $toggle.find('i')
            .toggleClass('far')
            .toggleClass('fas')
            .toggleClass('text-black-50');
        isEditingLiterals = !isEditingLiterals;
        return false;
    });
    $modal.on('show.bs.modal', function(){
        $text.val($target[0].innerHTML);
        $app.text('App: ' + $target.data('app'));
        $code.text('Code: ' + $target.data('code'));
        $locale.text('Locale: ' + $target.data('locale'));
    });
    $modal.on('shown.bs.modal', function(){
        $text.focus();
    });
    $form.on('submit',function (e){
        e.preventDefault();
        $text.attr('disabled', 'disabled');
        $s.post({
            url: '/literal',
            timeout: 180000,
            data: {
                text: $text.val(),
                app: $target.data('app'),
                code: $target.data('code'),
                locale: $target.data('locale')
            },
            complete: function(){
                $text.removeAttr('disabled');
                $modal.modal('hide');
            },
            success: function(){
                $target.html($text.val());
            }
        });
    });
    $(document).on('click', 'body.edit-literals .edit-literal', function(){
        $target = $(this);
        $modal.modal('show');
    });


    // edit-literal-toggle
    const $literals = $('[data-code]');
    $literals.each(function(){
        $(this).addClass('edit-literal')
    });
    if ($literals.length > 0) {
        $toggle.removeClass('d-none');
    }

});
