/* globals $, dayjs, bootbox, bootstrap */

(function () {

    $.validator.setDefaults({
        errorPlacement: function (error, element) {
            if (element.parent().is('.input-append'))
                error.insertAfter(element.parent());
            else
                error.insertAfter(element);
        }
    });
    $.validator.addMethod(
        "version-number",
        function (value, element) {
            var re = new RegExp("[0-9][.0-9]*$");
            var optional = this.optional(element);
            var isMatch = re.test(value);
            return optional || isMatch;
        },
        "Invalid version number. Digits and dots only. e.g. 1.0.3"
    );
    $.validator.addMethod(
        "voucher-code",
        function (value, element) {
            var re = new RegExp("^([\\w\\d-]+){3,200}$");
            var optional = this.optional(element);
            var isMatch = re.test(value); //  && (value.length >= 3 && value.length <= 200)
            return optional || isMatch;
        },
        "Invalid voucher code. Minimum 3, maximum 200 characters, letters, numbers and dashes only. e.g. SNP-A10459"
    );
    $.validator.addMethod(
        "schoolName",
        function (value, element) {
            return this.optional(element) || /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value);
        },
        "Invalid school name"
    );
    $.validator.addMethod(
        "schoolDomain",
        function (value, element) {
            return this.optional(element) || /^[A-Za-z0-9]+$/.test(value);
        },
        "Only letter and numbers, no spaces"
    );
    $.validator.addMethod(
        "phoneNumber",
        function (value, element) {
            return this.optional(element) || /^[0-9/\-\s]+$/.test(value);
        },
        "Only numbers spaces and -"
    );
    $.validator.addMethod(
        "supplier-code",
        function (value, element) {
            return this.optional(element) || /^[a-z]+$/.test(value);
        },
        "Only lower case letters, no spaces"
    );
    $.validator.addMethod(
        "username",
        function (value, element) {
            return this.optional(element) || /^[\S]?[a-zA-Z0-9_.-]+[@]?[a-zA-Z0-9_.-]*$/.test(value);
        },
        "Only letters, numbers or email addresses allowed."
    );
    $.validator.addMethod(
        "email",
        function (value, element) {
            return this.optional(element) || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(value);
        },
        "The email address is not valid."
    );

    $(function () {
        $('form:not(.no-validate)').each(function () {
            $(this).validate();
        });
    });

})();
