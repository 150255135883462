/* globals $, dayjs, bootbox, bootstrap */
/**
 * Typeahead
 * @CLEANUP this has many static variables not truly abstracted
 */
$(function () {

    const getSettings = function (autoLoad) {
        let minLength = !autoLoad ? 3 : 0;
        return {
            autoselect: true,
            highlight: true,
            minLength: minLength
        }
    };
    const getSource = function (type, input) {
        return new Bloodhound({
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.label);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: $s.withContext('/ta/' + type + '?query='),
                wildcard: '{q}',
                prepare: function (query, settings) {
                    const params = input.data('typeahead-params')
                    settings.url += encodeURIComponent(query);
                    settings.url += (!params ? '' : '&' + $.param(params));
                    return settings;
                }
            }
        })
    };
    const getTarget = function (el) {
        el = $(el);
        return $(el.data('target') || "#selected-" + el.data('typeahead'))
    };
    const onChange = function () {
        if ($(this).typeahead('val') !== $(this).data('selected')) {
            $(this).data('selected', null);
            $(this).typeahead('val', '');
            getTarget(this).val('');
        }
    };
    const onAsyncRequest = function () {
        $(this).closest('.twitter-typeahead').addClass('typeahead-busy');
    };
    const onAsyncReceive = function () {
        $(this).closest('.twitter-typeahead').removeClass('typeahead-busy');
    };
    const onSelect = function (e, v) {
        getTarget(this).val(v.id);
        $(this).typeahead('val', v.label);
        $(this).data('selected', v.label);
        $(this).typeahead('close');
    };

    $.fn.entityTypeAhead = function () {
        return $(this).each(function () {
            const input = $(this),
                type = input.data('typeahead'),
                autoload = input.data('autoload'),
                name = input.attr('id'),
                source = getSource(type, input);
            input.data('source', source);
            switch (type.toUpperCase()) {
                case 'USER':
                    userTypeahead(input, source, name, autoload);
                    break;
                case 'OWNER':
                case 'ACCOUNTMANAGER':
                case 'OWNERADVANCED':
                case 'ACCOUNTMANAGERADVANCED':
                case 'ALLUSER':
                case 'ENGAGEUSER':
                    userTypeahead(input, source, name, false, autoload);
                    break;
                case 'ASSET':
                case 'STOREASSET':
                    assetTypeahead(input, source, name, false, autoload);
                    break;
                case 'PACKAGE':
                    packageTypeahead(input, source, name, autoload);
                    break;
                case 'GRADE':
                case 'DISTRICT':
                case 'UNASSIGNEDSTORE':
                case 'CATEGORYTREE':
                case 'ASSETFORMAT':
                case 'BUSINESSRIGHT':
                case 'CURRICULUM':
                case 'SUBJECTCURRICULUM':
                case 'PERSONA':
                case 'COMPONENT':
                case 'ENGAGEGROUP':
                    input.typeahead({
                        autoselect: true,
                        highlight: true,
                        minLength: 0
                    }, {
                        display: 'label',
                        source: source,
                        limit: 999, // Infinity
                        templates: {
                            empty: function () {
                                return '<span class="tt-empty">No search results ...</span>'
                            }
                        }
                    })
                        .on('typeahead:asyncrequest', onAsyncRequest)
                        .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
                        .on('typeahead:selected', onSelect)
                        .on('change', onChange);
                    break;
                case 'DEEPLINKASSET':
                    deepLinkAssetTypeahead(input, source, name, false, autoload, type);
                    break;
                default:
                    input.typeahead(getSettings(autoload), {
                        display: 'label',
                        source: source,
                        limit: 999, // Infinity
                        templates: {
                            empty: function () {
                                return '<span class="tt-empty">No search results ...</span>'
                            }
                        }
                    })
                        .on('typeahead:asyncrequest', onAsyncRequest)
                        .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
                        .on('typeahead:selected', onSelect)
                        .on('change', onChange);
                    break;
            }
        });
    };

    $.fn.entityTypeAheadMulti = function () {
        return $(this).each(function () {
            let input = $(this),
                type = input.data('multitypeahead'),
                autoload = input.data('autoload'),
                name = input.attr('id'),
                source = getSource(type, input),
                selected = [];
            switch (type.toUpperCase()) {
                case 'USER':
                    userTypeahead(input, source, name, true, autoload);
                    break;
                case 'ASSET':
                case 'STOREASSET':
                case 'ORDERASSET':
                case 'CUSTOMERASSET':
                    assetTypeahead(input, source, name, true, autoload);
                    break;
                default:
                    input.typeahead(getSettings(autoload), {
                        name: name,
                        display: 'label',
                        source: source,
                        limit: Infinity,
                        templates: {
                            empty: function () {
                                return '<span class="tt-empty">No search results ...</span>'
                            }
                        }
                    });
                    break;
            }

            input.on('typeahead:selected', function (e, s) {
                $(this).data('selected', s.label);
                selected.push(s.id);
                $(".select-title-" + type).show()
                let html = `<tr>`
                html += `<td style="width: 100%">`
                html += buildAssetBlock(html, s)
                html += `</td>`
                html += `<td style="vertical-align: middle"><button type="button" class="btn modal-remove-asset btn-sm btn-danger"><i class="fa fa-times"></i></button></td>`
                html += `</tr>`
                $(".selected-" + type).append(html);
                $("#selected-" + type).val(selected.join(','));
                $(this).typeahead('val', '');
            });

        });
    };

    function userTypeahead(input, source, name, multi, autoload) {
        input.typeahead(getSettings(autoload), {
            name: name,
            display: 'label',
            source: source,
            limit: 100, // Infinity
            templates: {
                suggestion: function (data) {
                    return `
                    <div class="d-flex">
                        <div class="me-3">
                            <div id="" class="user-avatar ">
                                <div class="user-avatar-img loaded" title="" data-preload="${data.image}" style="background-image: url('${data.image}');"></div>
                            </div>
                        </div>
                        <div><span>${data.label} <small>(${data.username})</small></span></div>
                    </div>`
                },
                templates: {
                    empty: function () {
                        return '<span class="tt-empty">No search results ...</span>'
                    }
                }
            }

        })
            .on('typeahead:asyncrequest', onAsyncRequest)
            .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
            .on('change', onChange);
        if (!multi) {
            input.on('typeahead:selected', onSelect);
        }
    }

    function assetTypeahead(input, source, name, multi, autoload) {
        const autocomplete = input.typeahead(getSettings(autoload), {
            name: name,
            display: 'label',
            source: source,
            limit: 100, // Infinity
            templates: {
                suggestion: function (data) {
                    return buildAssetBlock('', data);
                },
                templates: {
                    empty: function () {
                        return '<span class="tt-empty">No search results ...</span>'
                    }
                }
            }
        });
        autocomplete
            .on('typeahead:asyncrequest', onAsyncRequest)
            .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
            .on('change', onChange);
        if (!multi) {
            input.on('typeahead:selected', onSelect);
        }
    }

    function deepLinkAssetTypeahead(input, source, name, multi, autoload, type) {
        const autocomplete = input.typeahead(getSettings(autoload), {
            name: name,
            display: 'label',
            source: source,
            limit: 100, // Infinity
            templates: {
                suggestion: function (data) {
                    return buildAssetBlock('', data);
                },
                templates: {
                    empty: function () {
                        return '<span class="tt-empty">No search results ...</span>'
                    }
                }
            }
        });
        autocomplete
            .on('typeahead:asyncrequest', onAsyncRequest)
            .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
            .on('change', onChange)
            .on('typeahead:selected', function (e, s) {
                $(this).data('selected', s.label);
                $(".select-title-" + type).show()
                let html = `<tr>`
                html += `<td style="width: 100%">`
                html += buildAssetBlock(html, s)
                html += `</td>`
                html += `</tr>`
                $(".selected-" + type).html(html);
                $("#selected-" + type).val(s.id);
                $(this).typeahead('val', '');
            });
    }

    function buildAssetBlock(html, data) {
        html += `<div class="d-flex mb-3">`
        html += `<div class="row-img jacket-image me-3 loaded" data-preload="${data.image}" style="background-image: url('${data.image}');"></div>`
        html += `<div>`
        html += `<div><span class="d-block">${data.label}</span></div>`
        html += `<div>`
        html += `<span class="badge badge-default badge-format me-1">${data.format}</span>`
        html += `<span class="text-muted">${data.identifier}</span>`
        html += `</div>`
        if (typeof data.relatedIdentifier !== 'undefined' && data.relatedIdentifier != null) {
            html += `<div><span class="text-muted">Related EAN: ${data.relatedIdentifier}</span></div>`
        }
        if (typeof data.language !== 'undefined' && data.language != null) {
            html += `<div><span class="text-muted">Supplied Language: ${data.language}</span></div>`
        }
        if (typeof data.gradeLevel !== 'undefined' && data.gradeLevel != null) {
            html += `<div><span class="text-muted">Supplied Grade: ${data.gradeLevel} </span></div>`
        }
        if (typeof data.supplierName !== 'undefined' && data.supplierName != null) {
            html += `<div class="text-truncate">${data.supplierName}</div>`
        }
        html += `</div>`
        html += `</div>`;
        return html;
    }

    function packageTypeahead(input, source, name, autoload) {
        let intervalId = -1;
        input.typeahead(getSettings(autoload), {
            name: name,
            display: 'label',
            source: source,
            limit: 100, // Infinity
            templates: {
                suggestion: function (data) {
                    return `
                    <div class="typeahead">
                        <div class="media-body">
                            <p class="media-heading">${data.label}<br />${data.identifier}</p>
                        </div>
                    </div>`;
                },
                templates: {
                    empty: function () {
                        return '<span class="tt-empty">No search results ...</span>'
                    }
                }
            }
        })
            .on('typeahead:asyncrequest', onAsyncRequest)
            .on('typeahead:asynccancel typeahead:asyncreceive', onAsyncReceive)
            .on('typeahead:selected', onSelect)
            .on('change', onChange)
            .on('typeahead:render', function () {
                // Automatically select the first suggestion, if there is only one result
                let $suggestions = input.closest('.twitter-typeahead').find('.tt-suggestion');
                if ($suggestions.length === 1) {
                    $($suggestions[0]).trigger('click');
                }
            })
            .on('focus', function () {
                var initval = input.val();
                clearInterval(intervalId);
                intervalId = setInterval(function () {
                    if (initval !== input.val()) {
                        initval = input.val();
                        input.trigger('input');
                        // trigger typeahead
                    }
                }, 250);
            })
            .on('blur', function () {
                clearInterval(intervalId);
            });
    }

    $('input[data-typeahead]').entityTypeAhead();
    $('input[data-multitypeahead]').entityTypeAheadMulti();

});

$(function () {

    let curriculum = $('select[name=curriculumId]'),
        subjectcurriculum = $("input[data-typeahead='subjectcurriculum']"),
        subjectcurriculumSelect = $('input[name=subjectcurriculumId]');

    curriculum.each(function () {
        $(this).on('change', function () {
            const curriculumId = $(this).children("option:selected").val();
            if (curriculumId !== "") {
                subjectcurriculum.prop("disabled", false);
                initSubjectTypeahead(curriculumId);
            } else {
                subjectcurriculum.prop("disabled", false);
                subjectcurriculum.val("");
                subjectcurriculumSelect.val("");
                subjectcurriculum.typeahead('destroy');
            }
        });
    });

    function initSubjectTypeahead(curriculumId) {
        subjectcurriculumSelect.val("");
        subjectcurriculum.val("");
        subjectcurriculum.typeahead('destroy');
        subjectcurriculum.typeahead({
            minLength: 0,
            highlight: true
        }, {
            name: 'subjectcurriculum',
            display: 'label',
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('label'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/ta/subjectcurriculum?query=%QUERY&curriculum=' + curriculumId,
                    wildcard: '%QUERY'
                }
            }),
            limit: 999, // Infinity
            templates: {
                empty: function () {
                    return '<span class="tt-empty">No search results...</span>'
                }
            }

        })
            .on('typeahead:asyncrequest', function () {
                $(this).closest('.twitter-typeahead').addClass('typeahead-busy');
            })
            .on('typeahead:asynccancel typeahead:asyncreceive', function () {
                $(this).closest('.twitter-typeahead').removeClass('typeahead-busy');
            })
            .on('typeahead:selected', function (e, s) {
                $("#selected-" + $(this).data('typeahead')).val(s.id);
                $(this).typeahead('val', s.label);
                $(this).data('selected', s.label);
                $(this).typeahead('close');
            })
            .on('change', function () {
                if ($(this).typeahead('val') !== $(this).data('selected')) {
                    $(this).typeahead('val', '');
                    $(this).data('selected', '');
                    $("#selected-" + $(this).data('typeahead')).val("");
                }
            });
    }
});
