/* globals $, dayjs, bootbox, bootstrap, Bloodhound */
import './common'

import './js/tagmanager'
import './js/literals'
import './js/fileupload'
import './js/country.dropdown'
import './js/pagination'
import './js/typeahead'
import './js/validator'
import './js/advanced.search'
import './scss/app.scss'
import 'expose-loader?exposes=Vue!vue';

window.$_GET = {};

if (document.location.toString().indexOf('?') !== -1) {
    let query = document.location
        .toString()
        .replace(/^.*?\?/, '')
        .replace(/#.*$/, '')
        .split('&');
    for (let i = 0, l = query.length; i < l; i++) {
        const aux = decodeURIComponent(query[i].replace("+", " ")).split('=');
        window.$_GET[aux[0]] = aux[1];
    }
}

$(document).on('click.bs.dropdown.data-api', '.app-navbar .dropdown', function (e) {
    e.stopPropagation();
});

$.fn.getTableItems = function () {
    const items = {items: [], elements: []};
    $(this).find('tbody input[type="checkbox"]:checked').each(function () {
        items.items.push($(this).val());
        items.elements.push($(this).closest('tr'));
    });
    return items;
};

// CSRF control, JSON overrides, global access
(function (window) {

    window.csrfToken = $("meta[name='_csrf']").attr("content");
    window.csrfHeader = $("meta[name='_csrf_header']").attr("content");
    window.contextPath = $("meta[name='_context']").attr("content");

    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': window.csrfToken}
    });

    const SnapplifyCtx = function () {
        return this;
    };
    $.extend(SnapplifyCtx.prototype, {

        withContext: function (url) {
            let ret = url;
            if (ret !== undefined && ret !== false && url !== null && ret !== '') {
                // var csrf = {_csrf: window.csrfToken, _csrf_header: window.csrfHeader};
                // ret = (ret.indexOf('?') === -1 ? ret + '?' : ret + '&') + $.param(csrf);
                if (ret.substring(0, 1) === '/' && ret.indexOf(contextPath) !== 0) {
                    ret = contextPath + ret
                }
            }
            return ret;
        },

        redirect: function (url) {
            window.location.href = this.withContext(url);
            return this;
        },

        open: function (url) {
            return window.open(this.withContext(url));
        },

        postForm: function (args) {
            args.contentType = false;
            args.processData = false;
            return this.post(args);
        },

        postDataAsBody: function (args) {
            args.processData = false;
            args.contentType = args.data != null && typeof args.data !== 'string' ? 'application/json; charset=UTF-8' : false;
            args.data = args.data != null && typeof args.data !== 'string' ? JSON.stringify(args.data) : args.data;
            return this.post(args);
        },

        postDataAsParams: function (args) {
            args.processData = true;
            return this.post(args);
        },

        postCsv: function (args) {
            args.contentType = 'text/csv';
            args.processData = false;
            return this.post(args);
        },

        getJSON: function (args) {
            args.type = 'GET';
            args.dataType = 'json';
            return this.get(args);
        },

        post: function (args) {
            args.type = 'POST';
            args.url = !args.url ? null : this.withContext(args.url);
            args.timeout = !args.timeout ? 30000 : args.timeout;
            return $.ajax(args);
        },

        get: function (args) {
            args.type = 'GET';
            args.url = !args.url ? null : this.withContext(args.url);
            args.timeout = !args.timeout ? 30000 : args.timeout;
            return $.ajax(args);
        },

        getParameters: function (url = '') {
            if (url === '' || url === undefined || !url)
                url = window.location.search
            let result = {}, searchIndex = url.indexOf("?");
            if (searchIndex === -1) return result;
            let sPageURL = url.substring(searchIndex + 1),
                sURLVariables = sPageURL.split('&');
            for (let i = 0; i < sURLVariables.length; i++) {
                let sParameterName = sURLVariables[i].split('=');
                result[sParameterName[0]] = decodeURIComponent(sParameterName[1].replace("+", " "));
            }
            return result;
        }
    });

    window.snapplify = new SnapplifyCtx();
    window.$s = new SnapplifyCtx();

})(window);

// Archive / unarchive ?
$(function () {
    $("a[data-bootbox]").each(function () {
        const tag = $(this);
        tag.on('click', function (e) {
            e.preventDefault();
            let type = tag.data('bootbox'),
                customMessage = tag.data('message'),
                message;
            if (typeof customMessage !== 'undefined') {
                message = customMessage;
            } else {
                switch (type.toUpperCase()) {
                    case 'ARCHIVE':
                        message = "Are you sure you want to archive?";
                        break;
                    case 'UNARCHIVE':
                        message = "Are you sure you want to un-archive?";
                        break;
                    case 'RESET-THEME':
                        message = "Are you sure you want to reset the theme to default?";
                        break;
                    case 'DISABLE':
                        message = "Are you sure you want to disable this customer?<br> The customers users will not have access to their Engage and or Origin instances.";
                        break;
                    case 'ACTIVATE':
                        message = "Are you sure you want to activate this customer?<br> The customers users will have access to their Engage and or Origin instances.";
                        break;
                    default:
                        message = "Are you sure ?";
                        break;
                }
            }
            bootbox.confirm({
                message: message,
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-outline-snapplify'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-outline-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        window.location.href = tag.attr('href');
                    }
                }
            });
        });
    });
});

// Redistribute?
$(function () {

    const redistribute = function (type, id) {
        return $s.getJSON({
            url: '/distribution/redistribute?type=' + type + '&id=' + id,
            success: function () {
                bootbox.alert("Items are being queued for re-distribution.");
            }
        });
    };

    $("a[data-redistribute]").each(function () {
        const a = $(this),
            type = a.data('type'),
            id = a.data('id');
        a.on('click', function () {
            bootbox.confirm({
                message: "Are you sure?",
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-outline-snapplify'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-outline-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        redistribute(type, id);
                    }
                }
            });
        });
    });

});

// Image upload
$(function () {
    $("form.image-upload-form").each(function () {
        const form = $(this),
            container = form.find('.show-image'),
            uploadbtn = form.find('.upload'),
            input = $('<input type="file" name="imageFile" accept="image/*" style="display:none;" />').appendTo(form);

        uploadbtn.on('click', function () {
            input.trigger('click');
            return false;
        });
        container.on('click', function () {
            const preload = container.data('preload');
            if (preload != null && container.hasClass('loaded')) {
                bootbox.alert("<div style='width: 80%; text-align: center;'><img alt='' style='max-width:80%;' src='" + encodeURI(preload) + "' /></div>");
            }
        });
        input.on('change', function () {
            form.trigger('submit');
            container.trigger('blur');
        });
    });
});

// Support dialog
$(function () {
    $('a.supportDialog').on('click', function () {
        $('#section').val(window.location.pathname);
    });

    $("#needHelpForm").each(function () {
        const form = $(this);
        form.on('submit', function () {
            $("#spinner").show();
            $s.postDataAsParams({
                url: '/need-help',
                data: form.serialize(),
                beforeSend: function () {
                    $("#spinner").hide();
                    $('#subject').val("");
                    $('.helpMessage').val("");
                    $('#SupportDialog').modal('hide');

                    bootbox.alert({
                        message: "Your message has been sent.",
                        buttons: {
                            ok: {
                                label: 'OK',
                                className: 'btn-outline-snapplify'
                            }
                        },
                        callback: function (result) {
                        }
                    });
                    setTimeout(function () {
                        bootbox.hideAll();
                    }, 2000);
                }

            });
            return false;
        });
    });
});

// Toggle blocks
$(function () {
    const toggleBlocked = $(".btn-blocked");

    const loadAll = function () {
        let items = toggleBlocked.map(function () {
            return $(this).data('id')
        }).toArray();
        const deferred = $.Deferred();
        if (items.length > 0) {
            return $s.postDataAsBody({
                url: '/marketplace/blocked-content/find',
                data: items,
                success: function (data) {
                    toggleBlocked.each(function () {
                        toggleUI($(this), data.indexOf($(this).data('id')) > -1);
                    });
                    deferred.resolve();
                },
                error: function (e) {
                    deferred.reject(e);
                }
            });
        } else {
            deferred.reject();
            return deferred.promise();
        }
    };

    const toggleUI = function (e, active) {
        if (active) {
            e.addClass("active");
            e.find('.btn-text').text("Blocked");
            $('.checkout-block').hide();
        } else {
            e.addClass("inactive");
            e.find('.btn-text').text("Block");
        }
    };

    const addBlock = function (id, blockBtn) {

        return bootbox.prompt({
            title: "Is this content inappropriate for your audience?",
            inputType: 'textarea',
            buttons: {
                confirm: {label: 'Yes', className: 'btn-outline-snapplify'},
                cancel: {label: 'No', className: 'btn-outline-secondary'}
            },
            callback: function (r) {
                if (r !== null) {
                    if (r) {
                        $s.postDataAsParams({
                            url: '/marketplace/blocked-content/add',
                            data: {id: id, reason: r},
                            success: function () {
                                toggleUI(blockBtn, true);
                                window.location.reload();
                            }
                        });
                    } else {
                        var form = $('.bootbox-form'),
                            textarea = form.find('.bootbox-input-textarea');
                        textarea.addClass("required-field");
                        form.append('<span class="help-block form-error">This is a required field</span>');
                        return false;
                    }
                } else {
                    return true;
                }
            }
        });
    };

    const removeBlock = function (id, blockBtn) {
        toggleUI(blockBtn, false);
        return $s.postDataAsParams({
            url: '/marketplace/blocked-content/remove',
            data: {id: id},
            success: function () {
                window.location.reload();
            }
        });
    };

    toggleBlocked.on('click', function () {
        const blockBtn = $(this),
            id = blockBtn.data('id');
        if (blockBtn.hasClass('active')) {
            removeBlock(id, blockBtn);
        } else if (blockBtn.hasClass('inactive')) {
            addBlock(id, blockBtn);
        }
        return false;
    });

    loadAll();
});

// Show more
$(function () {
    $('.show-more a').on('click', function (e) {
        e.preventDefault();
        const $this = $(this);
        let $content = $this.parent().prev('div.content'),
            linkText = $this.text().toUpperCase();
        if (linkText.toUpperCase() === "SHOW MORE") {
            linkText = "Show less";
            $content.addClass('showContent');
            $content.removeClass('hideContent');
        } else {
            linkText = "Show more";
            $content.addClass('hideContent');
            $content.removeClass('showContent');
        }
        $this.text(linkText);
    });
});

// Async lists / views
$(function () {
    let list = $('div[data-async-list]:not(.attached)');

    const loadingUI =
        '    <div class="async-list-loader">\n' +
        '        <i class="fas fa-sync fa-spin"></i>\n' +
        '        <span class="ms-2">Loading ...</span>\n' +
        '    </div>';
    const errorUI =
        '    <div class="async-list-loader">\n' +
        '        <i class="fas fa-exclamation-triangle"></i>\n' +
        '        <span class="ms-2">Something went wrong.</span>\n' +
        '    </div>';

    list.addClass('attached')
        .append(loadingUI);

    const loadRemoteHtmlContent = function ($e, url, $indicator) {
        let target = $e,
            options = $e.data();

        if (target.refreshTimer)
            clearTimeout(target.refreshTimer);
        if (target.refreshCoolDownTimer)
            clearTimeout(target.refreshCoolDownTimer);

        $indicator.addClass('busy');
        $s.get({
            url: url,
            timeout: 900000,
            success: function (data) {
                let base = url.split("?")[0],
                    $dom = $(data);
                $dom.find('div.pagination:not(.evented)').pagination({
                    url: base,
                    onPaginate: function (page, url) {
                        $dom.css('opacity', 0.5);
                        $dom.css('pointer-events', 'none');
                        // @CLEANUP not using the passed in URL here.
                        const urlParts = $e.data('url').split('?')
                        const queryParams = new URLSearchParams(urlParts[1] || '');
                        queryParams.set('page', page)
                        loadRemoteHtmlContent(target, urlParts[0] + "?" + queryParams.toString(), $indicator)
                    }
                });
                target.empty().append($dom);

                let autoRefreshTimeout = options['autoRefresh'] || 0;
                if (autoRefreshTimeout > 0) {
                    target.refreshTimer = setTimeout(function () {
                        loadRemoteHtmlContent($e, url, $indicator)
                    }, Math.max(250, autoRefreshTimeout))
                }

                target.refreshCoolDownTimer = setTimeout(function () {
                    $indicator.removeClass('busy')
                }, 1000);

                target.trigger("loadContentComplete")
                return false;
            },
            error: function () {
                $indicator.removeClass('busy');
                target.empty().append(errorUI);
            }
        });
    };

    list.each(function () {
        const $e = $(this), id = $e.attr('id');
        $e.on('loadContent', function () {
            loadRemoteHtmlContent($e, $e.data('url'), $(`span[data-bs-target="#${id}"]`));
        }).triggerHandler('loadContent');
    })

});

$(function () {

    const activityList = $('#activity-list');
    $("#noteForm").each(function () {
        let form = $(this),
            textarea = form.find('textarea'),
            btn = form.find('button');
        const toggleBtnActive = function () {
            btn.toggleClass('active', textarea.val() !== '');
        };
        form.on('focus blur change keyup', 'textarea', toggleBtnActive)
            .on('keypress', 'textarea', function (e) {
                if (e.which === 13 && !e.ctrlKey && !e.shiftKey) {
                    form.submit();
                    return false;
                }
            })
            .on('submit', function () {
                if (textarea.val() !== '') {
                    var data = form.serialize();
                    btn.attr('disabled', 'disabled');
                    textarea.attr('disabled', 'disabled').val('Sending ...').blur();
                    $s.postDataAsParams({
                        url: form.attr('action'),
                        data: data,
                        success: function () {
                            btn.removeAttr('disabled');
                            textarea.removeAttr('disabled').val('').focus();
                            activityList.triggerHandler('loadContent');
                        }
                    });
                }
                return false;
            });
    });

});

(function () {
    const max = 50;
    const activityTypeRegex = new RegExp('[?&]noteType=[^&]+', 'i');
    const startTypeRegex = new RegExp('[?&]s=[^&]+', 'i');
    const activityList = $('#activity-list');
    const getActivityTypeFromHref = function (e) {
        return e.getAttribute("href").substring(1);
    };
    const extractUrlValue = function (key, url) {
        const match = url ? url.match('[?&]' + key + '=([^&]+)') : false;
        return match ? match[1] : null;
    };
    const updateTabs = function (selected) {
        const tabs = $('#notesTab')
        tabs.find('a.active').removeClass('active')
        if (!selected) {
            tabs.find('a[href="#all"]').addClass('active')
        } else {
            tabs.find('a[href="#' + selected.toLowerCase() + '"]').addClass('active')
        }
    };
    $(document).on('shown.bs.tab', '#activity-list', function (e) {
        let activityList = $(this),
            nextType = getActivityTypeFromHref(e.target),
            url = activityList.data('url')
                .replace(activityTypeRegex, '')
                .replace(startTypeRegex, '');
        if (nextType !== 'all') {
            url = url + (url.indexOf('?') === -1 ? '?' : '&') + 'noteType=' + nextType.toUpperCase();
        }
        activityList.data('url', url);
        activityList.triggerHandler('loadContent');
        activityList.one('loadContentComplete', function () {
            updateTabs(nextType);
        })
    });
    activityList.one('loadContentComplete', function () {
        updateTabs(extractUrlValue('noteType', activityList.data('url')));
    });
    $(document).on('click', '#activity-list a[href="#more-activities"]', function (e) {
        let next = Number(extractUrlValue('s', activityList.data('url'), '0')) + max;
        let url = activityList.data('url').replace(startTypeRegex, '');
        url = url + (url.indexOf('?') === -1 ? '?' : '&') + 's=' + next;
        activityList.data('url', url);
        activityList.triggerHandler('loadContent');
        activityList.one('loadContentComplete', function () {
            updateTabs(extractUrlValue('noteType', activityList.data('url'), null));
        })
        e.preventDefault();
    });
})();

(function () {

    $(document).on("click", "button.modal-remove-asset", function () {
        const btn = $(this),
            row = btn.closest('tr');
        bootbox.confirm({
            message: "Are you sure?",
            buttons: {
                confirm: {label: 'Yes', className: 'btn-outline-snapplify'},
                cancel: {label: 'No', className: 'btn-outline-secondary'}
            },
            callback: function (result) {
                if (result) {
                    row.remove();
                }
            }
        });
        return false;

    });

    $('.dropdown')
        .on('show.bs.dropdown', function () {
            $("body").addClass("modal-open");
        })
        .on('hide.bs.dropdown', function () {
            $("body").removeClass("modal-open");
        });

    $(function () {
        const $tdAction = $('td.action');
        $tdAction.on('click', 'a.btn-add-asset', function () {
            let btn = $(this),
                id = $(this).data('id'),
                add = $(this).data('add');
            btn.prop('disabled', true);
            bootbox.confirm({
                message: "Are you sure you want to add the selected title?",
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-outline-snapplify'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-outline-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        $s.postDataAsParams({
                            url: add,
                            data: {id: id},
                            success: function (result) {
                                btn.removeClass("btn-success btn-add-asset").addClass("btn-danger btn-remove-asset");
                                btn.find('.fa-plus').removeClass('fa-plus').addClass('fa-times');
                                btn.tooltip('hide').attr('data-original-title', 'Remove Title');
                                $('.dash-well-container .dash-info-number').html(result.data)
                                btn.prop('disabled', false);
                            }
                        });
                    }
                }
            });
            return false;

        });
        $tdAction.on('click', 'a.btn-remove-asset', function () {
            let btn = $(this),
                id = $(this).data('id'),
                remove = $(this).data('remove');
            btn.prop('disabled', true);
            bootbox.confirm({
                message: "Are you sure you want to remove the selected title?",
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-outline-snapplify'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-outline-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        $s.postDataAsParams({
                            url: remove,
                            data: {id: id},
                            success: function (result) {
                                btn.removeClass("btn-danger btn-remove-asset").addClass("btn-success btn-add-asset");
                                btn.find('.fa-times').removeClass('fa-times').addClass('fa-plus');
                                btn.tooltip('hide').attr('data-original-title', 'Add Title');
                                $('.dash-well-container .dash-info-number').html(result.data)
                                btn.prop('disabled', false);
                            }
                        });
                    }
                }
            });
            return false;
        });
    });

})();

$(function () {

    const DATE_FORMAT_LOCAL = 'ddd D MMM YYYY';
    const DATE_FORMAT_DATETIME = 'ddd D MMM YYYY HH:mm:ss';

    function formatDate() {
        const $el = $(this),
            date = $el.data('date') ?? $el.text(),
            $f = $el.data('format') ?? ($el.data('local') || $el.data('isdate')) ? DATE_FORMAT_LOCAL : DATE_FORMAT_DATETIME,
            $m = dayjs(date);
        $el.addClass('date-set');
        $el.css({'display': ''})
        $el.attr('title', date);
        $el.text($el.data('fromnow') ? $m.fromNow() : $m.format($f));
    }

    window.applyDateTimeFormatting = function (el) {
        $(el ? el:document).find('datetime:not(.date-set),date:not(.date-set)')
            .each(formatDate);
    };
    $(document).on('loadContentComplete', function(e){
        window.applyDateTimeFormatting(e.target);
    });
    window.applyDateTimeFormatting(document);

});

(function () {
    $(function () {
        $(document).on('click', 'input[type="checkbox"].select-all', function () {
            let checked = $(this).prop('checked');
            let tbl = $(this).closest('table');
            tbl.find('tbody input[type="checkbox"]').each(function () {
                $(this).prop('checked', checked);
            });
        });
        $(document).on('click', '.collapse-head', function () {
            let header = $(this),
                bdy = header.next('.collapse-body');
            bdy.toggleClass('collapse-hide');
            header.toggleClass('collapse-hide');
        });

        $('span.health-error').tooltip();
        $('.duration-field .dropdown-item').on('click', function () {
            let text = $(this).text(),
                format = $(this).data('format'),
                input = $('.duration-field input'),
                $hrs = $('[data-format="hours"]'),
                $days = $('[data-format="days"]');

            switch (format) {
                case 'hours':
                    input.attr('max', 23);
                    $hrs.addClass("active");
                    $days.removeClass("active");
                    break;
                case 'days':
                    input.removeAttr('max');
                    $days.addClass("active");
                    $hrs.removeClass("active");
                    break;
            }
            const $appended = $('.duration-field .input-group-append');
            $('.duration-field .dropdown-menu input').attr('value', format);
            $appended.text(text);
            $('.duration-field .dropdown-menu').removeClass('show');
            $appended.removeClass('show')
            input.focus();
            return false;
        });
    });

    $(function () {
        // window.uiMethodsInit();
        $('.datepicker').datepicker();

        // Popover
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl, {})
        });

        // Tooltip
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });
    });
})();

$(document).ready(function(){
    window.lazyLoadImages();
});

$(document).on('loadContentComplete', function(e){
    $(e.target).find("input[data-typeahead]").entityTypeAhead();
    $(e.target).find("input[data-multitypeahead]").entityTypeAheadMulti();
    window.lazyLoadImages();
});
